/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { SVGRender } from '../../../helper/image';

const Logo = ({ svgUrl, urlLogo, id }) => {
	return (
		<Link to={urlLogo} className="navbar-brand" {...(id && { id })}>
			{SVGRender(svgUrl)}
		</Link>
	);
};

Logo.defaultProps = {
	id: '',
};

Logo.propTypes = {
	svgUrl: PropTypes.string.isRequired,
	urlLogo: PropTypes.string.isRequired,
	id: PropTypes.string,
};

export default Logo;
