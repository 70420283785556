import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { ReactSVG } from 'react-svg';
import queryString from 'query-string';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

import { SVGRender } from '../../../helper/image';
import Paragraph from '../../atoms/Label/Paragraph/Clear';
import ButtonLink from '../../atoms/Button/Link';
import ButtonPrimary from '../../atoms/Button/Primary';
import MenuNavbar from '../../atoms/Label/MenuNavbar';
import ResourceChild from './ResourceChild';
// import useToggle from '../../../hooks/useToggle';
import Logo from '../Logo';

const ListMenu = ({
	isMobile,
	onDemoClick,
	listMenu,
	titleButton,
	isOrangeButton,
	mtIdBtnRight,
	withRightButton,
	urlLogo,
	logo,
	onClose,
	enPrefix,
}) => {
	const [currentLocation, setCurrentLocation] = useState(undefined);
	const [isResouceOpen, setResourceOpen] = useState(false);
	const [isLangOpen, setLangOpen] = useState(false);
	const [addQueryString, setAddQueryString] = useState('');

	const intl = useIntl();

	const toggleResource = () => setResourceOpen(s => !s);

	useEffect(() => {}, []);

	useEffect(() => {
		const detectLocation = typeof window !== 'undefined' ? window.location.pathname : '';
		setCurrentLocation(detectLocation);

		const detectSearch = typeof window !== 'undefined' ? window.location.search : '';
		const parsedQueryString = queryString.parse(detectSearch);
		if (parsedQueryString.lang === 'en') {
			setAddQueryString('?lang=en');
		}
	}, []);

	const renderChildResources = () => {
		if (!isResouceOpen) return <></>;
		return (
			<OutsideClickHandler
				onOutsideClick={() => {
					setResourceOpen(false);
				}}
			>
				<ResourceChild
					enPrefix={enPrefix}
					currentLocation={currentLocation}
					addQueryString={addQueryString}
				/>
			</OutsideClickHandler>
		);
	};

	const renderChildLang = () => {
		if (!isLangOpen) return <></>;
		return (
			<OutsideClickHandler
				onOutsideClick={() => {
					setLangOpen(false);
				}}
			>
				<div className="child-nav">
					<ul>
						<li>
							<Link
								to={`/en${currentLocation.replace('/id', '')}`}
								className={enPrefix ? 'nav-link active' : 'nav-link'}
							>
								{enPrefix && <ReactSVG src="https://seiturju.sirv.com/Chatbiz/arrow-expand-right.svg" />}
								English Version
							</Link>
						</li>
						<li>
							<Link
								to={`${currentLocation.replace('/en', '')}?lang=id`}
								className={!enPrefix ? 'nav-link active' : 'nav-link'}
							>
								{!enPrefix && <ReactSVG src="https://seiturju.sirv.com/Chatbiz/arrow-expand-right.svg" />}
								Indonesia Version
							</Link>
						</li>
					</ul>
				</div>
			</OutsideClickHandler>
		);
	};

	const listClassCondition = linkTo => {
		let linkUrl = linkTo.replace(addQueryString, '');

		if (!enPrefix) {
			linkUrl = linkUrl.replace('?lang=id', '');
		}

		if (
			currentLocation === linkUrl ||
			currentLocation === `${linkUrl}/` ||
			currentLocation === `${linkUrl}id/`
		) {
			return 'nav-link active';
		}

		return 'nav-link';
	};

	const rightButton = () => {
		if (withRightButton) {
			return isMobile ? (
				<div className="btn-wrapper">
					<ButtonPrimary className="nav-link btnPrimaryDefault" onClick={onDemoClick} id={mtIdBtnRight}>
						<MenuNavbar>{intl.formatMessage({ id: `menu.register_on_mobile` })}</MenuNavbar>
					</ButtonPrimary>
				</div>
			) : (
				<div className="btn-wrapper">
					<ButtonLink
						className={
							isOrangeButton ? 'nav-link btnLinkDefault convToPrimary' : 'nav-link btnLinkDefault'
						}
						onClick={onDemoClick}
						id={mtIdBtnRight}
					>
						<MenuNavbar>{titleButton}</MenuNavbar>
					</ButtonLink>
				</div>
			);
		}

		return <></>;
	};

	return (
		<div className="navbar-nav ml-auto">
			<div className="mobile-top-nav">
				<Logo svgUrl={logo} urlLogo={urlLogo} id="mtNavLogo" />

				<div className="top-right-nav">
					<div className="lang-wrapper">
						<ReactSVG src="https://seiturju.sirv.com/Chatbiz/ico-lang.svg" className="ico-lang" />
						{currentLocation && (
							<>
								<Link to={`/en${currentLocation.replace('/id', '')}`} className={enPrefix && 'active'}>
									<Paragraph>EN</Paragraph>
								</Link>

								<Link
									to={`${currentLocation.replace('/en', '')}?lang=id`}
									className={!enPrefix && 'active'}
								>
									<Paragraph>ID</Paragraph>
								</Link>
							</>
						)}
					</div>
					<button
						type="button"
						className="ico-close"
						onClick={() => {
							onClose();
						}}
					>
						{SVGRender('https://seiturju.sirv.com/Chatbiz/ico-close.svg', 'ico-close')}
					</button>
				</div>
			</div>
			{listMenu.map(val => {
				if (val.isNewTab) {
					return (
						<div className="link-wrapper">
							<a
								href={val.linkTo}
								target="__blank"
								className={listClassCondition(val.linkTo)}
								id={val.mtId}
							>
								{val.name}
							</a>
						</div>
					);
				}

				if (val.isOutside) {
					return (
						<div className="link-wrapper">
							<a href={val.linkTo} className={listClassCondition(val.linkTo)} id={val.mtId}>
								{val.name}
							</a>
						</div>
					);
				}

				return (
					<div className="link-wrapper">
						<Link to={val.linkTo} className={listClassCondition(val.linkTo)} id={val.mtId}>
							{val.name}
						</Link>
					</div>
				);
			})}
			<div className="link-wrapper">
				<button className="nav-link child-parent" type="button" onClick={toggleResource}>
					{intl.formatMessage({ id: `menu.resources` })}
					{isResouceOpen ? (
						<>
							<ReactSVG
								src="https://seiturju.sirv.com/Chatbiz/arrow-up-black.svg"
								className="arrow-down"
							/>
						</>
					) : (
						<>
							<ReactSVG
								src="https://seiturju.sirv.com/Chatbiz/arrow-down-black.svg"
								className="arrow-down"
							/>
						</>
					)}
				</button>
				{renderChildResources()}
			</div>

			<div className="link-wrapper link-lang">
				<button className="nav-link child-parent" type="button" onClick={() => setLangOpen(s => !s)}>
					<ReactSVG src="https://seiturju.sirv.com/Chatbiz/ico-lang.svg" className="ico-lang" />
					{enPrefix ? 'EN' : 'ID'}
					{isLangOpen ? (
						<>
							<ReactSVG
								src="https://seiturju.sirv.com/Chatbiz/arrow-up-black.svg"
								className="arrow-down"
							/>
						</>
					) : (
						<>
							<ReactSVG
								src="https://seiturju.sirv.com/Chatbiz/arrow-down-black.svg"
								className="arrow-down"
							/>
						</>
					)}
				</button>
				{renderChildLang()}
			</div>
			{rightButton()}
		</div>
	);
};

ListMenu.defaultProps = {
	titleButton: 'Daftar Gratis',
	isOrangeButton: false,
	withRightButton: true,
};
ListMenu.propTypes = {
	isMobile: PropTypes.string.isRequired,
	onDemoClick: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	listMenu: PropTypes.objectOf(PropTypes.array).isRequired,
	titleButton: PropTypes.string,
	isOrangeButton: PropTypes.bool,
	mtIdBtnRight: PropTypes.string.isRequired,
	withRightButton: PropTypes.bool,
	logo: PropTypes.string.isRequired,
	urlLogo: PropTypes.string.isRequired,
	enPrefix: PropTypes.bool.isRequired,
};

export default ListMenu;
