import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import PropTypes from 'prop-types';

import Menu from '../../Menu';

const MenuSection = ({ enPrefix, onDemoClick }) => {
	const intl = useIntl();

	return (
		<Menu
			onDemoClick={() => {
				onDemoClick();
			}}
			listMenu={[
				{
					name: intl.formatMessage({ id: `menu.home` }),
					linkTo: enPrefix ? `/en` : `/?lang=id`,
					isNewTab: false,
					isOutside: false,
					mtId: 'btn-navbar-home',
				},
				{
					name: intl.formatMessage({ id: `menu.client` }),
					linkTo: '/blog/kategori/portofolio/',
					isNewTab: true,
					isOutside: false,
					mtId: 'btn-navbar-klien',
				},
			]}
			logo="https://seiturju.sirv.com/Chatbiz/homepage/ChatbizFooter.svg"
			withTopMenu={false}
			urlLogo={enPrefix ? `/en` : '/?lang=id'}
			titleButton={intl.formatMessage({ id: `menu.register` })}
			mtIdBtnRight="CTA-navbar"
			enPrefix={enPrefix}
		/>
	);
};

MenuSection.propTypes = {
	enPrefix: PropTypes.string.isRequired,
	onDemoClick: PropTypes.func.isRequired,
};

export default MenuSection;
