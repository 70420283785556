import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	&.menuNavbarDefault {
		font-family: Montserrat, sans-serif;
		font-style: normal;
		font-weight: bold !important;
		font-size: 0.813rem;
		line-height: 1.25em;
		color: #4f4f4f;
	}
`;

const MenuNavbar = ({ children, className }) => {
	return <Wrapper className={className || 'menuNavbarDefault'}>{children}</Wrapper>;
};

MenuNavbar.defaultProps = {
	className: '',
};

MenuNavbar.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default MenuNavbar;
