/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import Paragraph from '../../atoms/Label/Paragraph/Clear';

const ResourceChild = ({ enPrefix, currentLocation }) => {
	const intl = useIntl();

	const renderMenuItem = (url, id, title) => {
		const activeCondition = () => {
			let linkUrl = url;

			if (!enPrefix) {
				linkUrl = linkUrl.replace('?lang=id', '');
			}

			if (
				currentLocation === linkUrl ||
				currentLocation === `${linkUrl}/` ||
				currentLocation === `/en${linkUrl}` ||
				currentLocation === `/en${linkUrl}/`
			)
				return 'nav-link active';

			return 'nav-link';
		};

		return (
			<a
				href={enPrefix ? `/en${url}` : `${url}?lang=id`}
				target="_blank"
				className={activeCondition()}
				id={id}
			>
				<Paragraph>{title}</Paragraph>
			</a>
		);
	};

	const linkWhitePaper = renderMenuItem(
		'/white-paper',
		'btn-navbar-whitepaper',
		intl.formatMessage({ id: `menu.whitepaper` }),
	);

	const linkOpenSource = (
		<a
			href="https://github.com/chatbiz-id"
			target="_blank"
			rel="noreferrer"
			className="nav-link"
			id="btn-navbar-opensource"
		>
			<Paragraph>{intl.formatMessage({ id: `menu.open_source` })}</Paragraph>
		</a>
	);

	const linkPressKit = (
		<a
			href="https://drive.google.com/drive/folders/1gEUvWbfS40PYVHi5y1lIEZlfnyEkh8hv?usp=sharing"
			target="_blank"
			rel="noreferrer"
			className="nav-link"
			id="btn-navbar-presskit"
		>
			<Paragraph>{intl.formatMessage({ id: `menu.press_kit` })}</Paragraph>
		</a>
	);

	const linkBlog = (
		<a
			href={enPrefix ? '/blog' : '/blog'}
			target="_blank"
			rel="noreferrer"
			className="nav-link"
			id="btn-navbar-blog"
		>
			<Paragraph>{intl.formatMessage({ id: `menu.blog` })}</Paragraph>
		</a>
	);

	const linkHiring = (
		<a
			href="https://go.chatbiz.id/join"
			target="_blank"
			rel="noreferrer"
			className="nav-link"
			id="btn-navbar-join"
		>
			<Paragraph>{intl.formatMessage({ id: `menu.hiring` })}</Paragraph>
		</a>
	);

	const linkPartnership = renderMenuItem(
		'/joinpartnership',
		'btn-navbar-jadimitra',
		intl.formatMessage({ id: `menu.partnership` }),
	);

	return (
		<div className="child-nav">
			<div>
				<ul>
					<li>{linkWhitePaper}</li>
					<li>{linkPressKit}</li>
				</ul>
				<ul>
					<li>{linkBlog}</li>
					<li>{linkHiring}</li>

					<li>{linkPartnership}</li>
				</ul>
			</div>
		</div>
	);
};
ResourceChild.propTypes = {
	enPrefix: PropTypes.bool.isRequired,
	currentLocation: PropTypes.string.isRequired,
	// currentLocation: PropTypes.string.isRequired,
};
export default ResourceChild;
