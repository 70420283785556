/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useIntl, changeLocale } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { SVGRender } from '../../../helper/image';
import { Wrapper } from './style';

const Footer = ({ enPrefix }) => {
	const intl = useIntl();
	const [currentLocation, setCurrentLocation] = useState(undefined);

	useEffect(() => {
		const detectLocation = typeof window !== 'undefined' ? window.location.pathname : '';
		setCurrentLocation(detectLocation);
	}, []);

	const socialList = (
		<div className="footer__info__sns">
			<a
				href="https://api.whatsapp.com/send?phone=6289520004505&text=Saya%20ingin%20tahu%20tentang%20Chatbiz.id"
				target="_blank"
				rel="noreferrer"
				className="footer__info__sns--link"
				id="CTA-footer-whatsapp"
			>
				{SVGRender(
					'https://seiturju.sirv.com/Chatbiz/v4/ico-whatsapp-blue.svg',
					'footer__info__sns--image g-image',
					'',
					'',
					'',
				)}
			</a>
			<a
				href="https://instagram.com/chatbiz.id"
				target="_blank"
				rel="noreferrer"
				className="footer__info__sns--link"
				id="CTA-footer-instagram"
			>
				{SVGRender(
					'https://seiturju.sirv.com/Chatbiz/v4/ico-instagram-blue.svg',
					'footer__info__sns--image g-image',
					'',
					'',
					'',
				)}
			</a>
			<a
				href="https://linkedin.com/company/chatbiz-id/"
				target="_blank"
				rel="noreferrer"
				className="footer__info__sns--link"
				id="CTA-footer-linkedin"
			>
				{SVGRender(
					'https://seiturju.sirv.com/Chatbiz/v4/ico-linkedin-blue.svg',
					'footer__info__sns--image g-image',
					'',
					'',
					'',
				)}
			</a>
			<a
				href="mailto:admin@chatbiz.id"
				target="_blank"
				rel="noreferrer"
				className="footer__info__sns--link"
				id="CTA-footer-email"
			>
				{SVGRender(
					'https://seiturju.sirv.com/Chatbiz/v4/ico-mail-blue.svg',
					'footer__info__sns--image g-image',
					'',
					'',
					'',
				)}
			</a>
		</div>
	);

	const linkWhitePaper = (
		<a
			href={enPrefix ? '/en/white-paper' : '/white-paper?lang=id'}
			target="_blank"
			rel="noreferrer"
			className="item-link"
			id="btn-footer-whitepaper"
		>
			{intl.formatMessage({ id: `footer.resources.whitepaper` })}
		</a>
	);

	const linkBlog = (
		<a
			href={enPrefix ? '/blog' : '/blog?lang=id'}
			target="_blank"
			rel="noreferrer"
			className="item-link"
			id="btn-footer-blog"
		>
			{intl.formatMessage({ id: `footer.resources.blog` })}
		</a>
	);

	const linkOpenSource = (
		<a
			href="https://github.com/chatbiz-id"
			target="_blank"
			rel="noreferrer"
			className="item-link"
			id="btn-footer-opensource"
		>
			{intl.formatMessage({ id: `footer.resources.open_source` })}
		</a>
	);

	const linkPressKit = (
		<a
			href="https://drive.google.com/drive/folders/1gEUvWbfS40PYVHi5y1lIEZlfnyEkh8hv?usp=sharing"
			target="_blank"
			rel="noreferrer"
			className="item-link"
			id="btn-footer-presskit"
		>
			{intl.formatMessage({ id: `footer.resources.press_kit` })}
		</a>
	);

	const linkHiring = (
		<a
			href="https://go.chatbiz.id/join"
			target="_blank"
			rel="noreferrer"
			className="item-link"
			id="btn-footer-hiring"
		>
			{intl.formatMessage({ id: `footer.resources.hiring` })}
		</a>
	);

	const linkJadiMitra = (
		<a
			href={enPrefix ? `/en/joinpartnership` : '/joinpartnership?lang=id'}
			target="_blank"
			rel="noreferrer"
			className="item-link"
			id="btn-footer-jadimitra"
		>
			{intl.formatMessage({ id: `footer.resources.partnership` })}
		</a>
	);

	const linkAboutUs = (
		<Link
			to={enPrefix ? '/en/about-us' : '/about-us?lang=id'}
			className="item-link"
			id="btn-footer-tentangkami"
		>
			{intl.formatMessage({ id: `footer.company.about_us` })}
		</Link>
	);

	const linkPrivacyPolicy = (
		<Link
			to={enPrefix ? '/en/privacy-policy' : '/privacy-policy?lang=id'}
			className="item-link"
			id="btn-footer-kebijakanprivasi"
		>
			{intl.formatMessage({ id: `footer.company.privacy_policy` })}
		</Link>
	);

	const linkTermOfUse = (
		<Link
			to={enPrefix ? '/en/term-of-use' : '/term-of-use?lang=id'}
			className="item-link"
			id="btn-footer-ketentuanlayanan"
		>
			{intl.formatMessage({ id: `footer.company.term_of_use` })}
		</Link>
	);

	const RenderMoreResource = className => {
		return (
			<div className={`footer__nav__items ${className}`}>
				{linkPressKit}
				{linkHiring}
				{linkJadiMitra}
			</div>
		);
	};

	const copyright = '© 2021 - ';

	const switchLang = () => {
		return (
			<div className="footer__switchlang">
				<h6
					className="mb-0"
					onClick={() => {
						if (enPrefix) {
							window.location.href = `${currentLocation.replace('/en', '')}?lang=id`;
						} else {
							changeLocale('en');
						}
					}}
				>
					{intl.formatMessage({ id: `footer.switch_lang` })}
				</h6>
			</div>
		);
	};

	return (
		<Wrapper>
			<section id="footer" className="footer">
				<div className="container">
					<div className="row footer__main-wrapper">
						<div className="col-sm-12 col-md-5 col-lg-4">
							<div className="footer__logo ">
								<img
									alt=""
									src="https://seiturju.sirv.com/Chatbiz/homepage/ChatbizFooter.svg"
									className="img-fluid footer__logo--image g-image"
								/>
							</div>
							<div className="footer__info">
								<h5 className="footer__info__title">{intl.formatMessage({ id: `footer.pt` })}</h5>
								<p className="footer__info__text company_address">
									{intl.formatMessage({ id: `footer.company_address` })}
								</p>
							</div>
							<div className="footer__get-in-touch">
								<div className="footer__get-in-touch--title">
									{' '}
									{intl.formatMessage({ id: `footer.get_in_touch` })}
								</div>
								{socialList}
							</div>
						</div>
						<div className="footer__wrapper col-sm-12 col-md-7 col-lg-8">
							<div className="footer__company-desc">{intl.formatMessage({ id: `footer.desc` })}</div>

							<div className="footer__nav">
								<div className="footer__nav__items">
									<h5 className="footer__nav__items--title">
										{intl.formatMessage({ id: `footer.resources.title` })}
									</h5>
									{linkWhitePaper}
									{linkBlog}
									{linkOpenSource}
									{RenderMoreResource('more-on-tablet')}
								</div>

								{RenderMoreResource('hide-on-tablet')}

								<div className="footer__nav__items">
									<h5 className="footer__nav__items--title">
										{intl.formatMessage({ id: `footer.company.title` })}
									</h5>
									{linkAboutUs}
									{linkPrivacyPolicy}
									{linkTermOfUse}
								</div>
							</div>
						</div>
						<div className="col-sm-12 col-md-12">
							<div className="footer__copyright mt-auto">
								<h6 className="mb-0">
									{copyright} {intl.formatMessage({ id: `footer.pt` })}
								</h6>
							</div>
						</div>
					</div>

					<div className="row footer__main-wrapper--mobile">
						<div className="footer-mobile-list">
							<div className="footer-mobile-list-items">
								<h5 className="footer-mobile-list-items--title">
									{intl.formatMessage({ id: `footer.resources.title` })}
								</h5>
								{linkWhitePaper}
								{linkBlog}
								{linkOpenSource}
								{linkPressKit}
								{linkHiring}
								{linkJadiMitra}
							</div>

							<div className="footer-mobile-list-items">
								<h5 className="footer-mobile-list-items--title">
									{intl.formatMessage({ id: `footer.company.title` })}
								</h5>
								{linkAboutUs}
								{linkPrivacyPolicy}
								{linkTermOfUse}
							</div>
						</div>
						{switchLang()}

						<div className="footer-copyright">2021 - Chatbiz. Inc</div>
						<div className="footer-copyright footer-copyright-pt">PT Pemuda Inovasi Teknologi</div>
					</div>
				</div>
			</section>
		</Wrapper>
	);
};

Footer.propTypes = {
	enPrefix: PropTypes.string.isRequired,
};

export default Footer;
