/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';

import OutsideClickHandler from 'react-outside-click-handler';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { Wrapper } from './style';
import { MENU_MOBILE_BREAK_POINT } from './constants';
import ListMenu from '../../molecules/ListMenu';
import Logo from '../../molecules/Logo';
// import Paragraph from '../../atoms/Label/Paragraph/Clear';
// import { SVGRender } from '../../../helper/image';
import useScrollDirection from '../../../hooks/useScrollDirection';

const Navigation = ({
	urlLogo,
	onDemoClick,
	listMenu,
	logo,
	withTopMenu,
	theme,
	titleButton,
	backUrl,
	mtIdBtnRight,
	withRightButton,
	enPrefix,
}) => {
	const { width } = useWindowDimensions();
	const [sideShow, onSetShow] = useState(false);
	const [showContentTop] = useState(undefined);
	const [isOrangeButton, setOrangeButton] = useState(false);
	const { goingUp, currentY } = useScrollDirection();

	const isMobile = width > 0 && width <= MENU_MOBILE_BREAK_POINT;

	const isSideShow = isMobile && sideShow;
	// const currentLocation = typeof window !== 'undefined' ? window.location.pathname : '';
	// const splitLocation = currentLocation.split('/');
	// const isHome = splitLocation[1] === 'blog' && !splitLocation[2] && !splitLocation[3];
	const isHideTopNav = currentY > 100 && !goingUp;

	if (currentY > 100 && !isOrangeButton && !showContentTop) {
		setOrangeButton(true);
	}

	if (currentY <= 100 && isOrangeButton) {
		setOrangeButton(false);
	}

	const renderNavClassCondition = () => {
		const navBarClass = theme === 'blue-inverse' ? 'navbar blue-inverse' : 'navbar';
		if (withTopMenu && !isMobile) {
			if (isHideTopNav && !showContentTop) {
				return `${navBarClass} navbar-expand-md navbar-light fixed-top mtNav showNav hideNav`;
			}

			return `${navBarClass} navbar navbar-expand-md navbar-light fixed-top mtNav showNav`;
		}

		let renderClass = `${navBarClass} navbar navbar-expand-md navbar-light fixed-top`;

		if (isOrangeButton) {
			renderClass += ' withBoxShadow';
		}

		return renderClass;
	};

	const renderMenuList = () => {
		return (
			<div
				className={isSideShow ? 'collapse navbar-collapse showSideMenu' : 'collapse navbar-collapse'}
				id="navbarCollapse"
			>
				<ListMenu
					isMobile={isMobile}
					onDemoClick={onDemoClick}
					listMenu={listMenu}
					titleButton={titleButton}
					isOrangeButton={isOrangeButton}
					mtIdBtnRight={mtIdBtnRight}
					withRightButton={withRightButton}
					logo={logo}
					urlLogo={urlLogo}
					onClose={() => {
						onSetShow(false);
					}}
					enPrefix={enPrefix}
				/>
			</div>
		);
	};

	return (
		<Wrapper>
			<div className={isMobile && sideShow ? 'overlay active' : 'overlay'} />

			<nav className={renderNavClassCondition()}>
				<div className="container">
					{backUrl && (
						<a href={backUrl} className="btn-back-wrapper">
							<img src="https://seiturju.sirv.com/Chatbiz/homepage/arrow_back.svg" alt="arrowback" />
						</a>
					)}
					<Logo svgUrl={logo} urlLogo={urlLogo} id="mtNavLogo" />

					<button
						type="button"
						className="navbar-toggler"
						onClick={() => {
							onSetShow(true);
						}}
					>
						<img
							alt=""
							src="https://seiturju.sirv.com/Chatbiz/burger.svg"
							className="img-fluid g-image"
						/>
					</button>

					{isMobile ? (
						<OutsideClickHandler
							onOutsideClick={() => {
								onSetShow(false);
							}}
						>
							{renderMenuList()}
						</OutsideClickHandler>
					) : (
						renderMenuList()
					)}
				</div>
			</nav>
		</Wrapper>
	);
};

Navigation.defaultProps = {
	withTopMenu: true,
	theme: 'normal',
	titleButton: 'Daftar Gratis',
	backUrl: '',
	withRightButton: true,
};

Navigation.propTypes = {
	logo: PropTypes.string.isRequired,
	urlLogo: PropTypes.string.isRequired,
	onDemoClick: PropTypes.func.isRequired,
	listMenu: PropTypes.objectOf(PropTypes.array).isRequired,
	withTopMenu: PropTypes.bool,
	theme: PropTypes.string,
	titleButton: PropTypes.string,
	backUrl: PropTypes.string,
	mtIdBtnRight: PropTypes.string.isRequired,
	withRightButton: PropTypes.bool,
	enPrefix: PropTypes.bool.isRequired,
};

export default Navigation;
