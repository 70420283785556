import styled from 'styled-components';
import { MENU_MOBILE_BREAK_POINT } from './constants';
import {
	BlueChatbiz,
	Mobile,
	LightYellowChatbiz,
	LightBlueChatbiz,
	OrangeChatbiz,
} from '../../atoms/_Variable/var';

export const Wrapper = styled.div`
	.navbar {
		height: 5rem;
		background: rgba(255, 255, 255, 0.9);

		backdrop-filter: blur(8px);
		display: flex;
		flex-direction: column;
		width: 100%;

		@media screen and (max-width: ${Mobile}) {
			height: 4.5rem;
		}

		&.withBoxShadow {
			transition: box-shadow 500ms;

			box-shadow: 0px 0px 15px -8px #0076e3;
		}
		#CTA-navbar {
			@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
				display: inline-block;
				padding-left: 0 !important;
				padding-right: 0 !important;
				text-align: center;
			}
		}
		&.mtNav {
			margin-top: 50px;
			@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
				margin-top: 0;
			}

			@keyframes movingTopToBottomNav {
				0% {
					margin-top: 0;
				}

				100% {
					margin-top: 50px;
				}
			}
			@keyframes movingBottomToNav {
				0% {
					margin-top: 50px;
				}

				100% {
					margin-top: 0;
				}
			}
			&.showNav {
				animation: movingTopToBottomNav 0.2s linear;

				&.hideNav {
					animation: movingBottomToNav 0.2s linear;
					margin-top: 0;
				}
			}
		}

		.btn-back-wrapper {
			border: none;
			background-color: transparent;
			margin-right: 1.813rem;
		}
		#navbarCollapse > div > div.btn-wrapper > button {
			&.btnLinkDefault {
				padding: 0.625rem 0 !important;
				font-weight: 500;
				transition: all 0.5s ease-out;
				display: inline-block;
				text-align: center;
				min-width: 130px;

				div {
					font-weight: 500;
				}
			}
			&.convToPrimary {
				background: #ff8f1c;
				border: 1px solid #ff8f1c;
				transition: all 0.5s ease-out;
				padding: 0.625rem 0 !important;
				display: inline-block;
				text-align: center;
				min-width: 130px;
				&:hover {
					background-color: ${LightYellowChatbiz};
					border-color: ${LightYellowChatbiz} !important;
					color: #4f4f4f !important;
					div {
						color: #4f4f4f !important;
					}
				}
				&:active {
					background-color: ${LightBlueChatbiz};
					border-color: ${LightBlueChatbiz} !important;
					text-decoration: none;
					color: #ffffff !important;
					div {
						color: #ffffff !important;
					}
				}
				div {
					color: #ffffff;
				}
			}
		}

		@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
			.navbar-nav {
				flex-direction: column !important;
			}
			.navbar-toggler {
				display: block !important;
				&:focus {
					outline: none;
				}
			}
		}

		@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
			.container {
				display: flex;
				.navbar-brand {
					display: inline-block;
					padding-top: 0.25rem;
					padding-bottom: 0.25rem;
					margin-right: auto;
					margin-left: 20px;
				}
			}
		}
	}
	.overlay {
		display: none;
		position: fixed;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.7);
		z-index: 200;
		opacity: 0;
		transition: all 0.5s ease-in-out;

		top: 0;
		left: 0;
		&.active {
			display: block;
			opacity: 1;
		}
	}
	.navbar-toggleable-md .navbar-collapse {
		width: unset;
	}
	.navbar-collapse {
		flex-basis: unset;
	}
	.navbar-brand {
		font-weight: 500;
	}
	.navbar-brand img {
		height: 30px;
		margin-top: 13px;
		margin-bottom: 10px;
		margin-right: 5px;
	}

	.navbar-toggler {
		border: none;
	}

	.navbar-nav {
		display: flex;
		align-items: center;
		@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
			align-items: flex-start;
		}

		.mobile-top-nav {
			display: none;
			@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				.top-right-nav {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.ico-close {
					margin-right: 1.25rem;
					border: none;
					background-color: transparent;
					&:focus {
						outline: none;
					}
				}
			}

			@media screen and (max-width: ${Mobile}) {
				.navbar-brand {
					display: none;
				}
				.top-right-nav {
					width: 100%;
					justify-content: space-between;
					.lang-wrapper {
						width: auto;
					}
				}
			}
		}

		.link-wrapper {
			@media (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
				width: 100%;
				&:not(:first-child) {
					margin-top: 1.875rem;
				}
			}
			&:not(:last-child) {
				margin-right: 3.4375rem;
				@media (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
					margin-right: 0;
				}
			}
		}

		.btn-wrapper {
			@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
				width: 100%;
				padding-right: 1.25rem;
				margin-top: 1.875rem;
			}
		}
		.nav-link {
			font-style: normal;
			font-weight: normal;
			font-size: 1rem;
			line-height: 20px;
			color: #4f4f4f;

			&:hover {
				text-decoration: none;
				color: ${LightBlueChatbiz};
				path {
					fill: ${LightBlueChatbiz};
				}
			}
			&:focus {
				outline: 0;
			}
			&:active {
				color: ${OrangeChatbiz};

				path {
					fill: ${OrangeChatbiz};
				}
			}
			@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
				padding-right: 0;
				width: 100%;
				&:not(:first-child) {
					margin-top: 1.875rem;
				}
			}

			&.active {
				font-style: normal;
				font-weight: bold;
				font-size: 1rem;
				line-height: 20px;
				color: #066fc2 !important;
			}
		}

		.lang-wrapper {
			display: none;
			@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				padding-right: 23px;
				button,
				a {
					background-color: transparent;
					border: none;
					margin-left: 1rem;
					padding: 0;
					&:hover {
						text-decoration: none;
					}
					&:focus {
						outline: none;
					}
					&.active {
						.pDefault {
							color: ${BlueChatbiz};
						}
					}
					&:last-child {
						margin-left: 1.063rem;
					}
				}
			}
		}
	}

	@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
		.navbar-collapse {
			width: 60vw;
		}
		.navbar-collapse.showSideMenu {
			width: 60vw;
		}
	}

	@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
		.navbar-collapse {
			padding-top: 2rem;
			padding-left: 1rem;
			display: block !important;
			background-color: white;
			width: 100vw;
			height: 100vh;
			position: fixed;
			top: 0;
			right: -100vw;
			transition: all 0.3s ease-in;

			&.showSideMenu {
				padding-left: 1.8125rem;
				padding-top: 2.6875rem;
				transition: all 0.3s ease-in;
				display: block;
				background-color: white;
				width: 100vw;
				height: 100vh;
				position: fixed;
				top: 0;
				right: 0;
				overflow-x: auto;
			}
		}

		.navbar-collapse .menuMobile {
			display: flex;
			margin-bottom: 1.2rem;
		}
		.navbar-collapse .menuMobile div {
			font-size: 1.5em;
		}
		.navbar-collapse .menuMobile button {
			background: transparent;
			border: none;
			position: absolute;
			right: 2rem;
			cursor: pointer;
		}
		.navbar-collapse ul li {
			margin-bottom: 0.7rem;
		}
		.navbar-toggleable-md .navbar-nav .nav-link {
			padding-right: 0.7rem;
			padding-left: 0.7rem;
		}
	}

	.navbar {
		@media screen and (min-width: ${MENU_MOBILE_BREAK_POINT}px) {
			&.blue-inverse {
				background: ${BlueChatbiz};
				padding: 0;
				.btn-back-wrapper {
					img {
						content: url('https://seiturju.sirv.com/Chatbiz/homepage/arrow_back_white.svg');
					}
				}
				.container {
					height: 100%;
					.collapse.navbar-collapse {
						height: 100%;
						.navbar-nav.ml-auto {
							height: 100%;
							.link-wrapper {
								height: 100%;
								&:not(:last-child) {
									margin-right: 0;
									.nav-link {
										padding: 0 2.375rem;
									}
								}
							}
							.nav-link {
								color: #ffffff;
								&.active {
									background-color: #fff;
								}

								height: 100%;
								display: flex;
								align-items: center;
							}

							.btn-wrapper {
								margin-left: 2.875rem;
								.btnLinkDefault {
									border: 1px solid #fff;

									div {
										color: #fff !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.link-wrapper {
		&.link-lang {
			@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
				display: none;
			}
		}
		button {
			background-color: transparent;
			border: none;
			display: flex;
			align-items: center;
			.ico-lang {
				width: 24px;
				margin-right: 0.438rem;
			}
			.arrow-down {
				margin-left: 0.313rem;
			}
		}
	}

	.child-nav {
		position: fixed;
		background: #ffffff;
		box-shadow: 0px 0px 50px rgba(126, 169, 210, 0.15);
		border-radius: 8px;
		padding: 1.25rem;
		@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
			position: relative;
			background: transparent;
			box-shadow: none;
			border-radius: 8px;
			padding: 1.25rem 1.25rem 1.25rem 3rem;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				.nav-link {
					display: flex;
					svg {
						margin-right: 0.844rem;
					}
					&.active {
						color: rgb(32, 111, 194);
						font-weight: normal;
						div {
							color: rgb(32, 111, 194) !important;
							font-weight: normal;
						}
					}
				}
			}
		}
	}

	.child-nav-xl {
		position: fixed;
		background: #ffffff;
		box-shadow: 0px 0px 50px rgba(126, 169, 210, 0.15);
		border-radius: 8px;
		padding: 1.25rem;
		margin-left: -348px;

		margin-top: 7px;
		@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
			position: relative;
			background: transparent;
			box-shadow: none;
			border-radius: 8px;
			padding: 1.25rem 1.25rem 1.25rem 3rem;
			margin-left: 0;
		}

		.nav-desktop {
			@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) {
				display: none;
			}
		}

		.nav-tablet {
			display: none;
			@media screen and (max-width: ${MENU_MOBILE_BREAK_POINT}px) and (min-width: 481px) {
				display: unset;
			}
		}

		.nav-mobile {
			display: none;
			@media screen and (max-width: ${Mobile}) {
				display: unset;
				ul {
					&:not(:first-child) {
						li {
							.nav-link {
								padding-top: 1rem;
							}
						}
					}
					li {
						max-width: 100%;
						.nav-link {
							.pDefault {
								&:nth-child(2) {
									margin-bottom: 1rem;
								}
							}
						}
					}
				}
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			&:not(:first-child) {
				li {
					.nav-link {
						border-top: 1px solid #f3f5f6;
						padding-top: 1.25rem;
					}
				}
			}
			li {
				margin-right: 1rem;
				padding-right: 1rem;
				max-width: 240px;
				&:not(:last-child) {
					border-right: 1px solid #f3f5f6;
				}
				.nav-link {
					display: flex;
					flex-direction: column;
					.pDefault {
						&:nth-child(1) {
							font-weight: bold;
						}

						&:nth-child(2) {
							margin-top: 0.5rem;
							margin-bottom: 1.25rem;
						}
					}
					svg {
						margin-right: 0.844rem;
					}
					&.active {
						color: rgb(32, 111, 194);
						font-weight: normal;
					}
				}
			}
		}
	}
`;

export default Wrapper;
