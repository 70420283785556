import styled from 'styled-components';
import { BlueChatbiz, Tablet, Netbook } from '../../atoms/_Variable/var';

export const CUSTOM_TABLET = 900;

export const Wrapper = styled.div`
	position: relative;
	background-color: #ffff;
	.footer {
		padding: 100px 0;
		@media screen and (max-width: ${Netbook}) {
			padding: 50px 0;
		}
		.company_address {
			max-width: 314px;
		}
		.more-on-tablet {
			display: none;
			margin-top: 0;
			@media screen and (max-width: ${CUSTOM_TABLET}px) {
				display: flex;
			}
		}
		.hide-on-tablet {
			display: flex;
			margin-top: 0;
			@media screen and (max-width: ${CUSTOM_TABLET}px) {
				display: none;
			}
		}
		a.item-link {
			color: #4f4f4f;
			text-decoration: none;
			font-family: Nunito, sans-serif;
			font-style: normal;
			font-weight: bold;
			font-size: 1rem;
			line-height: 200%;

			&:hover {
				text-decoration: none;
				color: ${BlueChatbiz};
			}
		}

		&__main-wrapper {
			@media screen and (max-width: ${Tablet}) {
				display: none;
			}
			&--mobile {
				display: none;
				@media screen and (max-width: ${Tablet}) {
					display: flex;
					width: 100%;
					margin: 0;
					.footer-mobile-list {
						display: flex;
						width: 100%;
						&-items {
							display: flex;
							flex-direction: column;
							margin-top: 2.5rem;
							justify-content: flex-start;
							width: 50%;
							&--title {
								margin-bottom: 20px;
								font-family: Nunito, sans-serif;
								font-style: normal;
								font-weight: normal;
								font-size: 1rem;
								line-height: 140%;
								opacity: 0.6;
							}
						}
					}
					.footer__switchlang {
						margin-top: 2.188rem;
					}
					.footer__info__sns {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 2.5rem;
					}

					ul.footer-company-list {
						width: 100%;
						margin: 0;
						padding: 0;
						list-style: none;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
						margin-top: 2.063rem;
						li {
							a {
								font-family: Nunito, sans-serif;
								font-style: normal;
								font-weight: bold;
								font-size: 1rem;
								line-height: 200%;
								text-align: center;

								color: #4f4f4f;
								opacity: 0.6;
							}

							&:last-child {
								margin-left: 5rem;
								@media screen and (max-width: 380px) {
									margin-left: 3rem;
								}
							}
						}
					}

					.footer-copyright {
						font-family: Nunito, sans-serif;
						font-style: normal;
						font-weight: normal;
						font-size: 1rem;
						line-height: 20px;
						text-align: center;
						color: #4f4f4f;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-top: 1.875rem;
						width: 100%;
						opacity: 0.6;
						&-pt {
							margin-top: 0.5rem;
						}
					}
				}
			}
		}

		&__logo {
			margin-bottom: 60px;

			@media screen and (max-width: ${Tablet}) {
				margin-bottom: 30px;
			}

			&--image {
				width: 150px;
			}
		}

		&__copyright {
			padding-top: 3em;
			h6 {
				font-family: Nunito, sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 1rem;
				color: #4f4f4f;
				opacity: 0.6;
			}

			@media screen and (max-width: ${Tablet}) {
				display: none;
			}
		}

		&__switchlang {
			padding-top: 3em;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			h6 {
				font-family: Nunito, sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 1rem;
				color: #066fc2;
				@media screen and (max-width: ${Tablet}) {
					font-weight: bold;
					color: #066fc2 !important;
				}
			}

			@media screen and (max-width: ${Tablet}) {
				padding-top: 0;
			}
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
		}

		&__company-desc {
			font-family: Nunito, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 1rem;
			line-height: 150%;
			margin-bottom: 1.25rem;
			max-width: 684px;
		}

		&__get-in-touch {
			&--title {
				font-family: Nunito, sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 1rem;
				line-height: 150%;
				margin-bottom: 1.151;
				opacity: 0.6;
			}
			margin-bottom: 2.227rem;
		}

		&__nav {
			display: flex;

			@media screen and (max-width: ${Tablet}) {
				flex-direction: column;
			}

			&__items {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				width: 25%;
				margin-top: 2.5rem;
				@media screen and (max-width: ${CUSTOM_TABLET}px) {
					justify-content: flex-start;
					width: 35%;
				}
				@media screen and (max-width: ${Tablet}) {
					width: 100%;
					margin: 16px 0;
				}

				&--title {
					margin-bottom: 20px;
					font-family: Nunito, sans-serif;
					font-style: normal;
					font-weight: normal;
					font-size: 1rem;
					line-height: 140%;
					opacity: 0.6;
				}

				&--link {
					color: #4f4f4f;
					text-decoration: none;
					opacity: 0.7;

					@media screen and (max-width: ${Netbook}) {
						font-size: 12px;
					}

					&:hover {
						text-decoration: none;
						color: #4f4f4f;
					}
				}
			}
		}

		&__info {
			display: flex;
			flex-direction: column;

			&__title {
				margin-bottom: 20px;
				font-family: Montserrat;
				font-style: normal;
				font-weight: bold;
				font-size: 1rem;
				line-height: 140%;
				color: #4f4f4f;
				@media screen and (max-width: ${Netbook}) {
					font-size: 12px;
				}
			}

			&__text {
				font-family: Nunito, sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 1rem;
				line-height: 150%;
				color: #4f4f4f;
				@media screen and (max-width: ${Netbook}) {
					font-size: 12px;
				}
			}

			&__sns {
				margin-top: 16px;

				@media screen and (max-width: ${Netbook}) {
					margin-top: 8px;
				}

				&--link {
					margin: 0 16px;

					&:first-child {
						margin: 0 16px 0 0;
					}

					&:last-child {
						margin: 0 0 0 16px;
					}

					/* &:first-child {
						margin: 0;
					}

					&:last-child {
						margin: 0;
					} */
				}
			}
		}
	}
`;

export default Wrapper;
